import { ChangeEvent, FC, useMemo, useState } from 'react';
import Fuse from 'fuse.js';

import Pagination from 'components/design-system/Pagination';
import { useBranding } from 'context/branding';
import { useViewport } from 'context/viewport';
import { Listing } from 'types';

import { AboutSection } from './AboutSection';
import { ContactSection } from './ContactSection';
import { HeroSection } from './HeroSection';
import { HeroSectionMobile } from './HeroSectionMobile';
import Listings from './Listings';
import { SearchSection } from './SearchSection';
import { SocialLinks } from './socials';

const fuseOptions = {
  includeScore: true,
  keys: [
    'model.displayName',
    'model.brand.displayName',
    'model.brand.aliases',
    'model.referenceNumber',
    'model.releaseYear',
    'inventoryNumber'
  ]
};

interface SellerBodyProps {
  listings?: Listing[];
}

export const SellersBody: FC<SellerBodyProps> = ({ listings = [] }) => {
  const { sellerSiteMetadata } = useBranding();
  const [query, setQuery] = useState('');
  const fuse = useMemo(() => new Fuse(listings, fuseOptions), [listings]);

  const { isMobile } = useViewport();

  const filteredListings = useMemo(() => {
    if (query === '') {
      return listings;
    }

    return fuse.search(query).map(result => result.item);
  }, [listings, query, fuse]);

  if (!sellerSiteMetadata) {
    return null;
  }

  const {
    heroImage,
    darkLogoImage,
    lightLogoImage,
    caption,
    businessName,
    aboutTitle,
    aboutBody,
    businessAddress,
    contactEmail,
    hoursOfOperation,
    phoneNumber,
    socialLinks
  } = sellerSiteMetadata;

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value);

  return (
    <>
      {isMobile ? (
        <HeroSectionMobile
          imageUrl={heroImage?.optimizedUrl}
          sellerLogoDark={darkLogoImage?.optimizedUrl}
          caption={caption}
          title={businessName}
        />
      ) : (
        <HeroSection
          imageUrl={heroImage?.optimizedUrl}
          sellerLogoLight={lightLogoImage?.optimizedUrl}
          sellerLogoDark={darkLogoImage?.optimizedUrl}
          caption={caption}
          title={businessName}
        />
      )}
      <div className='container main-content pt-4 pt-md-5 px-3 px-md-4 position-relative' style={{ zIndex: 2 }}>
        <SearchSection
          onSearch={onSearch}
          query={query}
          sellerLogoDark={darkLogoImage?.optimizedUrl as string}
          businessName={businessName}
        />
        <Pagination
          items={filteredListings}
          pageSize={24}
          render={({ items }) => <Listings listings={items} isSearching={!!query} />}
        />
        {!!aboutBody && (
          <>
            <div className='w-100 border-top my-2' />
            <AboutSection description={aboutBody} title={aboutTitle} />
          </>
        )}
        {(!!businessAddress || !!contactEmail) && (
          <>
            <div className='w-100 border-top my-2' />
            <ContactSection
              address={businessAddress}
              email={contactEmail}
              phoneNumber={phoneNumber}
              workingHours={hoursOfOperation}
            />
          </>
        )}
        {!!socialLinks && socialLinks.length > 0 && <SocialLinks links={socialLinks} />}
      </div>
    </>
  );
};
