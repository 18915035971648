import 'react-loading-skeleton/dist/skeleton.css';

import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

export const ContentLoaderTestIds = {
  container: 'content-loader-container'
};

type ContentLoaderProps = {
  isDark?: boolean;
} & SkeletonProps;

const darkThemeColors = { baseColor: '#333', highlightColor: '#666' };

// to add border radius assign it through props like this <ContentLoader borderRadius={20} /> not using className
const ContentLoader: React.FC<ContentLoaderProps> = ({ className = 'w-100 h-100', isDark, ...props }) => (
  <Skeleton
    containerClassName={className}
    containerTestId={ContentLoaderTestIds.container}
    {...props}
    {...(isDark ? darkThemeColors : {})}
  />
);

export default ContentLoader;
