import { FC } from 'react';

import CloudinaryImage from 'components/common/CloudinaryImage';

interface MobileTopSectionProps {
  sellerLogoDark?: string;
  title?: string;
}

export const MobileTopSection: FC<MobileTopSectionProps> = ({ sellerLogoDark, title }) => (
  <div className='w-100 px-0 d-flex align-items-center justify-content-between' style={{ height: '58px' }}>
    {sellerLogoDark ? (
      <CloudinaryImage src={sellerLogoDark} width={130} height={38} alt='Bezel Logo' style={{ objectFit: 'contain' }} />
    ) : (
      <div className='riforma-medium'>{title}</div>
    )}
  </div>
);
