import { FC } from 'react';

import ContentLoader from 'components/design-system/ContentLoader';
import { useViewport } from 'context/viewport';
import { Listing } from 'types';

import ListingSellerCard from './ListingSellerCard';
import { SearchNotFoundPlaceholder } from './SearchNotFoundPlaceholder';

const loaders = Object.keys([...Array(6)]);

const loaderHeightDesktop = 400;
const loaderHeightMobile = 260;

interface ListingsProps {
  listings: Listing[];
  isSearching: boolean;
}

const Listings: FC<ListingsProps> = ({ listings, isSearching }) => {
  const { isMobileSmall } = useViewport();

  if (isSearching && !listings) {
    return <SearchNotFoundPlaceholder isMobile={isMobileSmall} />;
  }

  return (
    <div className='row w-100 gx-0 gy-3'>
      {!listings || listings?.length === 0 ? (
        <div className='d-flex flex-wrap justify-content-between w-100'>
          {loaders.map(num => (
            <ContentLoader
              borderRadius={16}
              className='mb-2 px-1 px-sm-2 col-6 col-sm-4'
              height={isMobileSmall ? loaderHeightMobile : loaderHeightDesktop}
              key={`seller-listing-card-loader-${num}`}
            />
          ))}
        </div>
      ) : (
        listings?.map(listing => (
          <ListingSellerCard key={`listing-${listing.id}`} className='col-sm-4 col-6 p-sm-2' listing={listing} />
        ))
      )}
    </div>
  );
};

export default Listings;
